import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, push } from 'firebase/database';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingHeart, faUserTie, faShieldAlt } from '@fortawesome/free-solid-svg-icons';

const Lavoro = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    number: '',
    read: false,
    selectedArticle: '',
  });

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const articlesRef = ref(db, 'blog');
    
    onValue(articlesRef, (snapshot) => {
      const data = snapshot.val();
      const filteredArticles = [];
      for (let key in data) {
        if (data[key].jobOffer === 'Si') {
          filteredArticles.push({
            id: key,
            title: data[key].title,
            content: data[key].content,
          });
        }
      }
      setArticles(filteredArticles);
    });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const db = getDatabase();
      const contactsRef = ref(db, 'candidature');
      await push(contactsRef, formData);
      setFormData({ name: '', email: '', message: '', read: false, selectedArticle: '' });
      alert('Messaggio inviato con successo!');
    } catch (error) {
      console.error("Errore durante l'invio del messaggio:", error);
      alert(
        "Si è verificato un errore durante l'invio del messaggio. Riprova più tardi."
      );
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-12 px-4 flex flex-wrap">
        <div className="w-full lg:w-1/2 p-4">
          <h2 className="text-3xl font-semibold text-[#0d3960] mt-12 mb-6">Chi siamo</h2>
          <p className="text-gray-600 mb-4">
            Siamo una rete di negozi compro oro in Italia, dedicati a offrire il miglior servizio ai nostri clienti.
          </p>
          <div className="mb-6">
            <div className="flex items-center mb-4">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                <FontAwesomeIcon icon={faHandHoldingHeart} className="text-white w-8 h-8" />
              </div>
              <div className="ml-4">
                <span className="text-gray-700 font-medium">Integrità</span>
                <p className="text-gray-600">
                  L'integrità è alla base del nostro lavoro. Ci impegniamo a operare in modo etico e trasparente con tutti i nostri clienti e partner.
                </p>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                <FontAwesomeIcon icon={faUserTie} className="text-white w-8 h-8" />
              </div>
              <div className="ml-4">
                <span className="text-gray-700 font-medium">Professionalità</span>
                <p className="text-gray-600">
                  La nostra professionalità è evidente in ogni aspetto del nostro lavoro. Siamo dedicati a offrire servizi di alta qualità con competenza e cortesia.
                </p>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                <FontAwesomeIcon icon={faShieldAlt} className="text-white w-8 h-8" />
              </div>
              <div className="ml-4">
                <span className="text-gray-700 font-medium">Affidabilità</span>
                <p className="text-gray-600">
                  Puoi contare su di noi per essere un partner affidabile e sicuro. La tua fiducia è la nostra priorità, e lavoriamo ogni giorno per meritarla.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 p-4">
          <div className="mt-12">
            <h2 className="text-2xl font-semibold text-[#0d3960] mb-4">
              Offerte di lavoro
            </h2>
            <ul>
              {articles.map((article) => (
                <li key={article.id} className="mb-4 p-4 border rounded-lg bg-white shadow-md">
                  <h3 className="text-xl font-bold text-gray-800">{article.title}</h3>
                  <p className="text-gray-700">{article.content}</p>
                </li>
              ))}
            </ul>
            <div className="mb-4 mt-4">
              <label htmlFor="selectedArticle" className="block text-gray-700 mb-1">
                Offerta di lavoro selezionata
              </label>
              <select
                id="selectedArticle"
                name="selectedArticle"
                value={formData.selectedArticle}
                onChange={handleChange}
                className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
              >
                <option value="">Seleziona un'offerta</option>
                {articles.map((article) => (
                  <option key={article.id} value={article.id}>
                    {article.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="max-w-lg w-full lg:max-w-full">
            <p className="text-gray-600 mb-4">
              Lasciaci ora la tua candidatura spontanea, verrai ricontattato al più presto.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 mb-1">
                  Nome
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="number" className="block text-gray-700 mb-1">
                  Numero di telefono
                </label>
                <input
                  type="number"
                  id="number"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-gray-700 mb-1">
                  Messaggio
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                ></textarea>
              </div>
             
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Invia
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lavoro;
