import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, remove } from 'firebase/database';
import { FaTrashAlt } from 'react-icons/fa';

const AdminContactMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const messagesRef = ref(db, 'candidature');

    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messagesList = Object.entries(data).map(([key, value]) => ({ ...value, id: key }));
        setMessages(messagesList);
        markMessagesAsRead(messagesList);
      } else {
        setMessages([]);
      }
    });
  }, []);

  const markMessagesAsRead = async (messagesList) => {
    const db = getDatabase();
    const updates = {};

    messagesList.forEach((message) => {
      if (!message.read) {
        updates[`candidature/${message.id}/read`] = true;
      }
    });

    if (Object.keys(updates).length > 0) {
      await update(ref(db), updates);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    const db = getDatabase();
    try {
      await remove(ref(db, `contacts/${messageId}`));
      const updatedMessages = messages.filter((message) => message.id !== messageId);
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Errore durante l\'eliminazione del messaggio:', error);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
    <h1 className="text-4xl font-extrabold text-center mt-10 mb-8 text-gray-900">Candidature ricevute</h1>
    {messages.length === 0 ? (
      <p className="text-center text-gray-600">Nessun messaggio ricevuto.</p>
    ) : (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {messages.map((message) => (
          <div key={message.id} className="bg-white shadow-lg rounded-lg p-6 relative hover:shadow-xl transition-shadow duration-300">
            <button 
              className="absolute top-3 right-3 bg-red-600 hover:bg-red-700 rounded-full p-2 focus:outline-none"
              onClick={() => handleDeleteMessage(message.id)}
            >
              <FaTrashAlt className="text-white" />
            </button>
            <div className="mb-4">
              <h3 className="text-xl font-bold text-gray-900 mb-1">
                {message.name}
              </h3>
              <p className="text-gray-700">
                <span className="font-medium">Email:</span> {message.email}
              </p>
            </div>
            <p className="text-gray-700">Messaggio: {message.message}</p>
          </div>
        ))}
      </div>
    )}
  </div>
  );
};

export default AdminContactMessages;
